import { SECRET_OTP_KEY, apiUrls } from '../api/apiUrls';
import { getApiRequest, postApiRequest } from '../api/api';
import {
  type SendOtpRequestPayload,
  type VerifyOtpRequestPayload,
  type VerifyOtpRequestSuccessPayload,
  type CancelMembershipOfUserSuccessPayload
} from '../redux/cancelMemberShip/types';
import { type AxiosError } from 'axios';
import { PHONE_COUNTRY_CODE_IN } from '../constants';
import CryptoJS from 'crypto-js';
import { store } from '../redux/store';
import { type AppState } from '../redux/rootReducer';

export function otpEncryptKey (mobileNo: string) {
  const cipherText = CryptoJS.HmacSHA256(mobileNo, SECRET_OTP_KEY);
  return cipherText.toString();
}

export const getBaseUrl = () => {
  const env = process.env.REACT_APP_ENV;
  const { cancelMemberShip } = store.getState() as AppState;
  if (env === 'prod') {
    switch (cancelMemberShip?.countryCode) {
      case 'IN':
        return process.env.REACT_APP_IN_BASE_URL;
      case 'PK':
        return process.env.REACT_APP_PK_BASE_URL;
      case 'ID':
        return process.env.REACT_APP_ID_BASE_URL;
      case 'BD':
        return process.env.REACT_APP_BD_BASE_URL;
      case 'TH':
        return process.env.REACT_APP_TH_BASE_URL;
      case 'MY':
        return process.env.REACT_APP_MY_BASE_URL;
      case 'VN':
        return process.env.REACT_APP_VN_BASE_URL;
      case 'PH':
        return process.env.REACT_APP_PH_BASE_URL;
      case 'ZM':
        return process.env.REACT_APP_ZM_BASE_URL;
      default :
        return process.env.REACT_APP_IN_BASE_URL;
    }
  } else {
    return apiUrls.BASE_URL;
  }
};

export const cancelMembershipGetOtp = async (body: SendOtpRequestPayload) => {
  try {
    const { mobile, phoneCountryCode } = body;

    const baseUrl = getBaseUrl();

    let url: string =
    phoneCountryCode === PHONE_COUNTRY_CODE_IN
      ? `${baseUrl}${
          apiUrls.SEND_OTP_URL
        }${phoneCountryCode}${mobile}?encryptedKey=${otpEncryptKey(
          `${PHONE_COUNTRY_CODE_IN}${mobile}`
        )}`
      : `${baseUrl}${
          apiUrls.SEND_OTP_URL
        }${phoneCountryCode}${mobile}`;
    url =
    url +
    `${phoneCountryCode === PHONE_COUNTRY_CODE_IN ? '&' : '?'}source=web`;
    const response: unknown = await getApiRequest(url);
    return response;
  } catch (error) {
    const errorResponse = error as AxiosError;
    throw errorResponse;
  }
};

export const verifyOtp = async (body: VerifyOtpRequestPayload) => {
  const baseUrl = getBaseUrl();
  console.log(baseUrl);
  try {
    const { phoneCountryCode, identifier, mobile } = body;
    const url: string = `${baseUrl}${
      apiUrls.VERIFY_OTP_URL
    }${phoneCountryCode}${mobile}`;
    const response: VerifyOtpRequestSuccessPayload = await postApiRequest(url, {
      identifier
    });
    return response;
  } catch (error) {
    const errorResponse = error as AxiosError;
    throw errorResponse;
  }
};

export const cancelMembershipOfuser = async (body: any) => {
  const baseUrl = getBaseUrl();
  try {
    const url: string = `${baseUrl}${
      apiUrls.CANCEL_MEMBERSHIP_OF_USER_URL
    }`;
    const response: CancelMembershipOfUserSuccessPayload = await postApiRequest(
      url,
      {}
    );
    return response;
  } catch (error) {
    const errorResponse = error as AxiosError;
    throw errorResponse;
  }
};
