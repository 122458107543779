import { all, call, put, takeLatest } from 'redux-saga/effects';
import { CANCEL_MEMBERSHIP_OF_USER, CANCEL_MEMBERSHIP_SEND_OTP, CANCEL_MEMBERSHIP_VERIFY_OTP } from './actionType';
import {
  requestOtpSuccess,
  requestOtpFailed,
  requestVerifyOtpSuccess,
  requestVerifyOtpFailed,
  requestCancelMembershipOfUserSuccess,
  requestCancelMembershipOfUserFailed
} from './actions';
import { type CancelMembershipOfUserSuccessPayload, type ErrorType, type SendOtpRequest, type VerifyOtpRequest, type VerifyOtpRequestSuccessPayload } from './types';
import { cancelMembershipGetOtp, cancelMembershipOfuser, verifyOtp } from '../../services/cancelMemberShip';

function * cancelMemberShipSendOtp (action: SendOtpRequest) {
  try {
    yield call(cancelMembershipGetOtp, action.payload);
    yield put(requestOtpSuccess({ otpSent: true }));
  } catch (error: unknown) {
    const errorResponse = error as ErrorType;
    yield put(requestOtpFailed({ error: errorResponse }));
  }
}

function * cancelMemberShipofUser (action: any) {
  try {
    const response: CancelMembershipOfUserSuccessPayload = yield call(cancelMembershipOfuser, action.payload);
    yield put(requestCancelMembershipOfUserSuccess(response));
  } catch (error: unknown) {
    yield put(requestCancelMembershipOfUserFailed({ error: true }));
  }
}

function * verifyOtpRequest (action: VerifyOtpRequest) {
  try {
    const response: VerifyOtpRequestSuccessPayload = yield call(verifyOtp, action.payload);
    yield put(requestVerifyOtpSuccess(response));
  } catch (error: unknown) {
    yield put(requestVerifyOtpFailed({ error: true }));
  }
}

function * cancelMemberShipSaga () {
  yield all([takeLatest(CANCEL_MEMBERSHIP_SEND_OTP, cancelMemberShipSendOtp)]);
  yield all([takeLatest(CANCEL_MEMBERSHIP_VERIFY_OTP, verifyOtpRequest)]);
  yield all([takeLatest(CANCEL_MEMBERSHIP_OF_USER, cancelMemberShipofUser)]);
}

export default cancelMemberShipSaga;
